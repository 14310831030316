import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import './style.scss'
export default class LongTermRentalProp extends Component {
  renderSeparatorMobile = () => {
    return (
      <div className="prop-separator">
        <div>
          <svg height="40" width="20">
            <line x1="0" y1="40" x2="18" y2="20" />
            <line x1="0" y1="0" x2="18" y2="20" />
          </svg>
        </div>
        <div />
      </div>
    )
  }
  renderSeparatorDesktop = () => {
    return (
      <div className="prop-separator">
        <div>
          <svg height="60" width="30">
            <line x1="0" y1="60" x2="28" y2="30" />
            <line x1="0" y1="0" x2="28" y2="30" />
          </svg>
        </div>
        <div />
      </div>
    )
  }

  renderSeparator = () => {
    return (
      <MediaQuery maxWidth={768}>
        {matches => {
          if (matches) {
            return this.renderSeparatorMobile()
          } else {
            return this.renderSeparatorDesktop()
          }
        }}
      </MediaQuery>
    )
  }

  render() {
    return (
      <div className="rental-prop-container">
        <div className="rental-prop__header">
          <h2>
            เช่ารถระยะยาวกับเรา
            <span className="display-block--tablet">ต้องทำอย่างไร?</span>
          </h2>
        </div>
        <div className="rental-prop__content">
          <div className="content-list__item">
            <div className="prop-item">
              <img src="/assets/fill-form-icon.png" alt="Fill form" />
              <p>
                1. กรอกแบบฟอร์มเสนองบประมาณ{' '}
                <span className="display-block--tablet">เพื่อให้เราติดต่อกลับ</span>
              </p>
            </div>
          </div>
          {this.renderSeparator()}
          <div className="content-list__item">
            <div className="prop-item">
              <img src="/assets/car-select-icon.png" alt="Select your car" />
              <p>
                2. ทางเราจะหารถคันที่เหมาะสมให้คุณ{' '}
                <span className="display-block--tablet">
                  และส่งรูปให้คุณดูก่อนตัดสินใจเลือกเช่า
                </span>
              </p>
            </div>
          </div>
          {this.renderSeparator()}
          <div className="content-list__item">
            <div className="prop-item">
              <img src="/assets/confirm-icon.png" alt="Confirm book and wait for response" />
              <p>3. ยืนยันและรอรับรถจากร้านรถเช่า</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
