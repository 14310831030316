import React, { Component } from 'react'
import './style.scss'
import { withTranslation } from '../locales'

class Contact extends Component {
  render() {
    const { t } = this.props
    return (
      <div className="help-contact__container">
        <h2 className="help-contact__header">{t('contact.help')}</h2>
        <div className="help-contact__wrapper">
          <div className="help-contact__item">
            <img
              className="call-center__image"
              src="/assets/callcenter.png"
              alt="Drivehub"
            />
          </div>
          <a
            href="https://line.me/ti/p/%40drivehub"
            className="help-contact__item"
            rel="nofollow"
          >
            <img
              className="help-contact__icon"
              src="/assets/line-icon.svg"
              alt="line@:@drivehub"
            />
            <p style={{ lineHeight: '22px' }}>
              {t('contact.line')} <br />
              <span>@drivehub</span>
            </p>
          </a>
          <a
            rel="nofollow"
            href="tel:+6620385222"
            className="help-contact__item"
          >
            <i className="flaticon-phone-icon tel-icon--blue" />
            <p style={{ marginBottom: 18, lineHeight: '22px' }}>
              {t('contact.phone')} <br />
              <span>02-038-5222</span>
            </p>
          </a>
        </div>
      </div>
    )
  }
}
export default withTranslation('common')(Contact)
