import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import Layout from '../layout'
import SEO from '../seo'
import LongTermRentalHeader from '../long-term-rental-header'
import LongTermRentalHeaderProp from '../long-term-rental-header-prop'
import Contact from '../contact'
import LongTermRentalProp from '../long-term-rental-prop'
import LongTermRentalForm from '../long-term-rental-form'
import StickyButton from '../sticky-button'
import { WEB_LEGACY_LINK } from '../../../config'
import './style.scss'

const LongTermRental = props => (
  <StaticQuery
    query={graphql`
      query {
        headerDesktop: file(relativePath: { eq: "assets/long-term-rental-header.png" }) {
          childImageSharp {
            fluid(maxWidth: 4000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        headerMobile: file(relativePath: { eq: "assets/long-term-rental-header-mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 4000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <Index {...props} data={data} />}
  />
)

class Index extends Component {
  state = {
    isMobile: true,
  }

  componentDidMount() {
    const initialWidth = window.innerWidth
    this.setState({ isMobile: initialWidth < 768 ? true : false })

    let widthChecker = window.matchMedia('(max-width: 768px)')
    widthChecker.addListener(e => {
      if (e.matches) {
        this.setState({ isMobile: true })
      } else {
        this.setState({ isMobile: false })
      }
    })
  }

  render() {
    const { isMobile } = this.state
    const { data, prefix } = this.props
    const headerDesktop = data.headerDesktop.childImageSharp.fluid
    const headerMobile = data.headerMobile.childImageSharp.fluid

    return (
      <Layout
        isMobile={isMobile}
        prefix={prefix}
        menuStyle={isMobile ? null : { position: 'absolute', width: '100%', zIndex: 10 }}
      >
        <SEO
          title="Long Term Rental"
          prefix={prefix}
          path="long-term-rental"
          canonical={`${WEB_LEGACY_LINK}/${prefix}/long-term-rental/`}
          description="Drivehub เช่ารถระะยาว เปิดบริการแล้ว รับราคาพิเศษ ง่ายๆ เพียงติดต่อเรา!"
          keywords="เช่ารถ, รถเช่า, ระยะยาว, 7วัน, 15 วัน"
        />
        <div className="long-term-rental">
          <BackgroundImage
            Tag="section"
            className="header"
            fluid={isMobile ? headerMobile : headerDesktop}
          >
            <div className="container header-wrapper">
              <LongTermRentalHeader />
            </div>
          </BackgroundImage>
          {isMobile && (
            <div className="container mt-4">
              <LongTermRentalHeaderProp />
            </div>
          )}

          <div className="contact-wrapper">
            <Contact />
          </div>
          <div className="value-prop">
            <div className="container">
              <LongTermRentalProp />
            </div>
          </div>
          <div className="form" id="long-term-rental__form">
            <div className="container">
              <LongTermRentalForm />
            </div>
          </div>
        </div>
        {isMobile && <StickyButton prefix={prefix} />}
      </Layout>
    )
  }
}

export default LongTermRental
