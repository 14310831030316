import React, { Component } from 'react'
import './style.scss'
/**
 * @param {number} active - Index of open tab start with 0
 */
export default class Tab extends Component {
  static defaultProps = {
    active: 0,
  }
  constructor(props) {
    super(props)

    const { active, children } = props
    if (children.length === 0) {
      this.state = {
        isOtherOpen: false,
        open: 0,
      }
    } else {
      this.state = {
        index: active,
        open: active,
        isOtherOpen: false,
        openContent: active,
      }
    }
  }

  componentDidMount = () => {
    if (window) {
      const hash = window.location.hash.split('#')[1]
      const tabs = this.props.children
      tabs.forEach((tab, index) => {
        if (hash) {
          if (tab.props.hash === hash) {
            return this.setState({ open: index, openContent: index })
          }
        }
      })
    }
  }

  getSnapshotBeforeUpdate(props) {
    if (this.props.children.length !== props.children.length) {
      const { active } = this.props
      this.setState({
        index: active,
        open: active ? active : this.state.open,
        isOtherOpen: false,
        openContent: active ? active : this.state.open,
      })
    }
    return true
  }

  renderOptions() {
    const { isMobile } = this.props
    const restTabs = isMobile
      ? this.props.children.slice(2)
      : this.props.children.slice(5)
    const roundIndex = isMobile ? 2 : 5

    return (
      <div className="drop-down--container drop-down--car-with-driver">
        {restTabs.map((tab, index) => {
          return (
            <div
              key={index}
              className={`${this.state.open === index + roundIndex &&
                'drop-down--selected'} drop-down-list`}
              onClick={() =>
                this.setState({
                  open: index + roundIndex,
                  openContent: index + roundIndex,
                  isOtherOpen: false,
                })
              }
            >
              <p>{tab.props.title}</p>
            </div>
          )
        })}
      </div>
    )
  }
  render() {
    if (!this.props.children) {
      return <div />
    }

    let tabs = this.props.children

    let heading = tabs
    if (tabs.length > 5 && !this.props.isMobile) {
      heading = tabs.slice(0, 5)
    } else {
      heading = tabs.slice(0, 2)
    }
    return (
      <div className="tabs">
        <div className="title--container">
          {heading.map((tab, index) => (
            <div
              onClick={() => {
                this.setState(
                  {
                    index,
                    open: index,
                    openContent: index,
                    isOtherOpen: false,
                  },
                  () => {
                    if (tab.props.hash) {
                      window.history.pushState('', '', `#${tab.props.hash}`)
                    }
                  },
                )
              }}
              key={index}
              className={index === this.state.open ? 'active' : ''}
            >
              {tab.props.title}
            </div>
          ))}
          {tabs.length > 5 && (
            <div
              onClick={() =>
                this.setState({
                  isOtherOpen: !this.state.isOtherOpen,
                  open: 'other',
                })
              }
              className={
                heading.map((h, index) => index).includes(this.state.open)
                  ? ''
                  : 'active'
              }
            >
              อื่นๆ
            </div>
          )}
        </div>
        <div className="tab--content">
          {tabs.map((tab, index) => {
            if (index === this.state.openContent) {
              return (
                <div key={index} className="show">
                  {tab}
                </div>
              )
            }
            return null
          })}
          {this.state.isOtherOpen && this.renderOptions()}
        </div>
      </div>
    )
  }
}

// const objectToString = object => {
//   return Object.keys(object).reduce((p, c) => {
//     if (typeof object[c] === 'symbol') return p
//     return p + c + object[c]
//   }, '')
// }
/**
 * @param  {object} props
 * @param  {string} props.title
 * @param  {string} props.hash
 */
export const TabPane = ({ children }) => {
  return <div>{children}</div>
}
