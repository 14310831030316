import React, { Component } from 'react'
import { Formik, Field } from 'formik'
import * as yup from 'yup'
import provinces from './provinces.json'
import { withTranslation } from '../locales'
import moment from 'moment'

function Checkbox(props) {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              {...props}
              checked={field.value.includes(props.value)}
              onChange={() => {
                if (field.value.includes(props.value)) {
                  const nextValue = field.value.filter(
                    value => value !== props.value,
                  )
                  form.setFieldValue(props.name, nextValue)
                } else {
                  const nextValue = field.value.concat(props.value)
                  form.setFieldValue(props.name, nextValue)
                }
              }}
            />
            {` ${props.value}`}
          </label>
        </div>
      )}
    </Field>
  )
}

function Select(props) {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <div>
          <select
            className="form-control"
            name="province"
            onChange={e => form.setFieldValue(props.name, e.target.value)}
            value={field.value}
          >
            {props.options.map(value => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
      )}
    </Field>
  )
}

function DatePicker(props) {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <input
          type="date"
          min={moment().format('YYYY-MM-DD')}
          onChange={e => {
            const date = moment(e.target.value, 'YYYY-MM-DD')
            form.setFieldValue(props.name, date)
            if (props.name === 'pickup_date') {
              form.setFieldValue(
                'return_date',
                moment(e.target.value, 'YYYY-MM-DD').endOf('month'),
              )
            }
          }}
          value={field.value.format('YYYY-MM-DD')}
          className="form-control"
          placeholder="DDMM YY"
        />
      )}
    </Field>
  )
}

function TimePicker(props) {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <input
          type="time"
          className="form-control"
          step={1800}
          onChange={e => form.setFieldValue(props.name, e.target.value)}
          value={field.value}
        />
      )}
    </Field>
  )
}

class Quotation extends Component {
  get schema() {
    const { t } = this.props
    const phoneRegExp = /([0-9]{9})$/

    return yup.object().shape({
      full_name: yup.string().required(t('required.full_name')),
      phone_number: yup
        .string()
        .matches(phoneRegExp, t('invalid.phone_number'))
        .required(t('required.phone_number')),
      email: yup
        .string()
        .email(t('invalid.email'))
        .required(t('required.email')),
      province: yup.string().required(t('required.province')),
      budget: yup.string().required(t('required.budget')),
    })
  }
  get provinces() {
    return provinces[this.props.lang]
  }
  buttonText(errors) {
    if (this.props.isLoading) {
      return 'กำลังส่งใบเสนอราคา...'
    }
    if (Object.keys(errors).length > 0) {
      return errors[Object.keys(errors)[0]]
    }
    return this.props.t('quotation.submit')
  }
  render() {
    const { t } = this.props
    return (
      <div>
        <h3>{t('detail.title')}</h3>
        <br />
        <Formik
          initialValues={{
            car_type: t(carTypes[0]),
            full_name: '',
            email: '',
            phone_number: '',
            time: timeOptions[0],
            province: this.provinces[0],
            purposes: [],
            purpose_other: '',
            pickup_date: moment().add(3, 'days'),
            return_date: moment()
              .add(3, 'days')
              .add(1, 'months'),
            pickup_hour: '10:00',
            return_hour: '10:00',
            budget: budgets[0],
            car_type_other: '',
            note: '',
          }}
          validateOnChange
          validateOnBlur
          onSubmit={values => {
            const newValues = { ...values }
            if (values.purposes.includes(t('purposes.other'))) {
              newValues.purposes.push(values.purpose_other)
              newValues.purposes = newValues.purposes.filter(
                p => p !== t('purposes.other'),
              )
            }
            if (values.car_type === t('cars.other')) {
              newValues.car_type = values.car_type_other
            }
            newValues.pickup_at = `${values.pickup_date.format(
              'DD MMMM YYYY',
            )} ${values.pickup_hour}`
            newValues.return_at = `${values.return_date.format(
              'DD MMMM YYYY',
            )} ${values.return_hour}`
            delete newValues.pickup_date
            delete newValues.return_date
            delete newValues.pickup_hour
            delete newValues.return_hour
            delete newValues.purpose_other
            delete newValues.car_type_other
            return this.props.onSubmit(newValues)
          }}
          validationSchema={this.schema}
          render={props => (
            <form onSubmit={props.handleSubmit}>
              <input
                type="hidden"
                name="form-name"
                value={this.props.formName}
              />
              <input type="hidden" name="pickup_at" />
              <input type="hidden" name="return_at" />
              <div className="row mb-3">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>{t('detail.carType_label')}</label>
                    <select
                      name="car_type"
                      className="form-control"
                      onChange={props.handleChange}
                      value={props.values.car_type}
                    >
                      {carTypes.map(type => (
                        <option value={t(type)} key={type}>
                          {t(type)}
                        </option>
                      ))}
                    </select>
                  </div>
                  {props.values.car_type === t('cars.other') && (
                    <div className="form-group">
                      <input
                        name="car_type_other"
                        className="form-control"
                        onChange={props.handleChange}
                        value={props.values.car_type_other}
                        placeholder={t('cars.other')}
                      />
                    </div>
                  )}
                  <div className="form-group">
                    <label>{t('detail.budget_label')}</label>
                    <select
                      name="budget"
                      className="form-control"
                      onChange={props.handleChange}
                      value={props.values.budget}
                    >
                      {budgets.map(budget => (
                        <option value={budget} key={budget}>
                          {budget}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>{t('detail.purposes_label')}</label>
                    {purposes.map(purpose => (
                      <Checkbox
                        name="purposes"
                        key={purpose}
                        value={t(purpose)}
                        raw={purpose}
                      />
                    ))}
                    {props.values.purposes.includes(t('purposes.other')) && (
                      <input
                        name="purpose_other"
                        className="form-control"
                        value={props.values.purpose_other}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>{t('detail.province_label')}</label>
                    <Select
                      name="province"
                      options={this.provinces}
                      placeholder={t('detail.province_label')}
                    />
                  </div>
                  <div className="form-group">
                    <label>{t('detail.pickup_label')}</label>
                    <div className="row">
                      <div className="col-8">
                        <DatePicker
                          name="pickup_date"
                          placeholder={t('detail.pickupDate_placeholder')}
                        />
                      </div>
                      <div className="col-4 pl-0">
                        <TimePicker
                          name="pickup_hour"
                          placeholder={t('detail.pickupHour_placeholder')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>{t('detail.return_label')}</label>
                    <div className="row">
                      <div className="col-8">
                        <DatePicker
                          name="return_date"
                          placeholder={t('detail.returnDate_placeholder')}
                        />
                      </div>
                      <div className="col-4 pl-0">
                        <TimePicker
                          name="return_hour"
                          placeholder={t('detail.returnHour_placeholder')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h3>{t('personal.title')}</h3>
              <div>{t('personal.subTitle')}</div>
              <br />
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>{t('personal.fullName_label')}</label>
                    <input
                      name="full_name"
                      className="form-control"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.full_name}
                      placeholder={t('personal.fullName_placeholder')}
                    />
                  </div>
                  <div className="form-group">
                    <label>{t('personal.email_label')}</label>
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.email}
                      placeholder={t('personal.email_placeholder')}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>{t('personal.phoneNumber_label')}</label>
                    <input
                      name="phone_number"
                      className="form-control"
                      type="tel"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.phone_number}
                      placeholder={t('personal.phoneNumber_placeholder')}
                    />
                  </div>
                  <div className="form-group">
                    <label>{t('personal.time_label')}</label>
                    <select
                      name="time"
                      value={props.values.time}
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      className="form-control"
                    >
                      {timeOptions.map(value => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <h3>{t('detail.note')}</h3>
                    <textarea
                      name="note"
                      className="form-control"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.note}
                      placeholder={t('detail.note_placeholder')}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-md-center mt-3">
                <div className="col-12 col-md-6">
                  <button
                    disabled={
                      Object.keys(props.errors).length > 0 ||
                      this.props.isLoading === true
                    }
                    type="submit"
                    className="btn btn-primary btn-block"
                  >
                    {this.buttonText(props.errors)}
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    )
  }
}

const timeOptions = [
  '08:00 - 12:00',
  '12:00 - 13:00',
  '13:00 - 15:00',
  '15:00 - 17:00',
  '17:00 - 18:00',
  '18:00 - 20:00',
]
const carTypes = [
  'cars.eco',
  'cars.small',
  'cars.medium',
  'cars.large',
  'cars.suv',
  'cars.other',
]
const budgets = [
  '18,000 - 25,000',
  '25,000 - 35,000',
  '35,000 - 45,000',
  '45,000 - 50,000',
]
const purposes = [
  'purposes.waiting',
  'purposes.company',
  'purposes.travel',
  'purposes.other',
]

export default withTranslation('long-term-rental-form', 'common')(Quotation)
