import React from 'react'
import './style.scss'

const LongTermRentalHeaderProp = props => {
  return (
    <div className="long-term-rental-header-prop">
      <h2>
        เช่ารถระยะยาวกับ <br /> กับไดรฟ์ฮับดีอย่างไร
      </h2>
      <div>
        <img src="/assets/budget-specify-icon.png" alt="Your own budget" />
        <p>กำหนดงบได้</p>
      </div>
      <div>
        <img src="/assets/good-new-car-icon.png" alt="New car and resonable price" />
        <p>รถใหม่ ราคาดี</p>
      </div>
      <div>
        <img src="/assets/preview-car-icon.png" alt="See real car before book" />
        <p>เห็นรถจริงก่อนเช่า</p>
      </div>
    </div>
  )
}

export default LongTermRentalHeaderProp
