import React, { Component } from 'react'
import './style.scss'

export default class StickyButton extends Component {
  render() {
    const { prefix } = this.props
    return (
      <div className="sticky-button__container">
        <a
          rel="noreferrer"
          href={`/${prefix}/help-center`}
          className="faq__box"
        >
          <i className="flaticon-round" />
          <p>อ่านวิธีการจอง, เอกสาร และอื่นๆ</p>
        </a>
        <div className="contact__container">
          <a
            rel="noreferrer"
            href="https://line.me/ti/p/%40drivehub"
            className="contact__box contact__box--left"
          >
            <div className="contact__icon">
              <i className="flaticon-line-icon" />
            </div>
            <div className="contact__text">
              <p>
                ติดต่อผ่านไลน์ <br /> <span>@drivehub</span>
              </p>
            </div>
          </a>
          <a
            rel="noreferrer"
            href="tel:+6620385222"
            className="contact__box contact__box--right"
          >
            <div className="contact__icon">
              <i className="flaticon-phone-icon" />
            </div>
            <div className="contact__text">
              <p>
                ติดต่อผ่าน Call Center <br />
                <span>02-038-5222</span>
              </p>
            </div>
          </a>
        </div>
      </div>
    )
  }
}
