import React from 'react'
import { Provider } from '../../../components/locales'
import LongTermRental from '../../../components/long-term-rental'

export default () => {
  return (
    <Provider value="th">
      <LongTermRental prefix="th" />
    </Provider>
  )
}
