import React, { Component } from 'react'
import { withTranslation } from '../locales'
import { Formik, Field } from 'formik'
import * as yup from 'yup'

function Checkbox(props) {
  return (
    <Field name={props.name}>
      {({ field, form }) => (
        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              {...props}
              checked={field.value.includes(props.value)}
              onChange={() => {
                if (field.value.includes(props.value)) {
                  const nextValue = field.value.filter(value => value !== props.value)
                  form.setFieldValue(props.name, nextValue)
                } else {
                  const nextValue = field.value.concat(props.value)
                  form.setFieldValue(props.name, nextValue)
                }
              }}
            />
            {` ${props.value}`}
          </label>
        </div>
      )}
    </Field>
  )
}
class Contact extends Component {
  get schema() {
    const { t } = this.props
    const phoneRegExp = /([0-9]{9})$/

    return yup.object().shape({
      full_name: yup.string().required(t('required.full_name')),
      phone_number: yup
        .string()
        .matches(phoneRegExp, t('invalid.phone_number'))
        .required(t('required.phone_number')),
      email: yup
        .string()
        .email(t('invalid.email'))
        .required(t('required.email')),
    })
  }

  buttonText(errors) {
    if (this.props.isLoading) {
      return 'กำลังส่งใบเสนอราคา...'
    }
    if (Object.keys(errors).length > 0) {
      return errors[Object.keys(errors)[0]]
    }
    return this.props.t('personal.submit')
  }
  render() {
    const { t } = this.props

    return (
      <div>
        <h3>{t('personal.title')}</h3>
        <p>{t('personal.subTitle')}</p>
        <Formik
          initialValues={{
            full_name: '',
            email: '',
            phone_number: '',
            time: timeOptions[0],
            purposes: [],
            purpose_other: '',
          }}
          validateOnChange
          validateOnBlur
          onSubmit={values => {
            const newValues = { ...values }
            if (values.purposes.includes(t('purposes.other'))) {
              newValues.purposes.push(values.purpose_other)
              newValues.purposes = newValues.purposes.filter(p => p !== t('purposes.other'))
            }
            delete newValues.purpose_other
            return this.props.onSubmit(newValues)
          }}
          validationSchema={this.schema}
          render={props => (
            <form onSubmit={props.handleSubmit}>
              <input type="hidden" name="form-name" value={this.props.formName} />
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>{t('personal.fullName_label')}</label>
                    <input
                      name="full_name"
                      className="form-control"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.full_name}
                      placeholder={t('personal.fullName_placeholder')}
                    />
                  </div>
                  <div className="form-group">
                    <label>{t('personal.email_label')}</label>
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.email}
                      placeholder={t('personal.email_placeholder')}
                    />
                  </div>

                  <div className="form-group">
                    <label>{t('detail.purposes_label')}</label>
                    {purposes.map(purpose => (
                      <Checkbox name="purposes" key={purpose} value={t(purpose)} raw={purpose} />
                    ))}
                    {props.values.purposes.includes(t('purposes.other')) && (
                      <input
                        name="purpose_other"
                        className="form-control"
                        value={props.values.purpose_other}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>{t('personal.phoneNumber_label')}</label>
                    <input
                      name="phone_number"
                      className="form-control"
                      type="tel"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.phone_number}
                      placeholder={t('personal.phoneNumber_placeholder')}
                    />
                  </div>
                  <div className="form-group">
                    <label>{t('personal.time_label')}</label>
                    <select
                      name="time"
                      value={props.values.time}
                      onBlur={props.handleBlur}
                      onChange={props.handleChange}
                      className="form-control"
                    >
                      {timeOptions.map(value => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row justify-content-md-center mt-3">
                <div className="col-12 col-md-6">
                  <button
                    disabled={Object.keys(props.errors).length > 0 || this.props.isLoading === true}
                    type="submit"
                    className="btn btn-primary btn-block"
                  >
                    {this.buttonText(props.errors)}
                  </button>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    )
  }
}

const timeOptions = [
  '08:00 - 12:00',
  '12:00 - 13:00',
  '13:00 - 15:00',
  '15:00 - 17:00',
  '17:00 - 18:00',
  '18:00 - 20:00',
]

const purposes = ['purposes.waiting', 'purposes.company', 'purposes.travel', 'purposes.other']
export default withTranslation('long-term-rental-form', 'common')(Contact)
