import React from 'react'
import './style.scss'

const LongTermRentalHeader = props => {
  return (
    <div className="long-term-rental-header">
      <div className="header-box">
        <span>เช่ารถระยะยาวกับเรา</span>
        <h1>รายอาทิตย์และรายเดือน</h1>
        <ul>
          <li>
            <i className="flaticon-checked-circle" /> กำหนดงบได้
          </li>
          <li>
            <i className="flaticon-checked-circle" /> รถใหม่ ราคาดี
          </li>
          <li>
            <i className="flaticon-checked-circle" /> เห็นรถจริงก่อนเช่า
          </li>
        </ul>
        <button
          className="btn btn-lg btn-primary"
          onClick={() =>
            document.getElementById('long-term-rental__form').scrollIntoView({ behavior: 'smooth' })
          }
        >
          เริ่มต้นเช่ารถระยะยาวกับเรา
        </button>
      </div>
    </div>
  )
}

export default LongTermRentalHeader
