import React, { Component } from 'react'
import { navigate } from 'gatsby'
import axios from 'axios'
import { API_LONG_TERM_RENTAL } from '../../../config'
import Tab, { TabPane } from '../tab'
import { withTranslation } from '../locales'
import Contact from './contact'
import Quotation from './quotation'
import './style.scss'

const FORM_NAME_CONTACT = 'long-term-rental-contact-form'
const FORM_NAME_QUOTATION = 'long-term-rental-quotation-form'
class LongTermRentalForm extends Component {
  state = {
    isLoading: false,
  }
  onSubmitContact = values => {
    this.setState({ isLoading: true })
    axios({
      method: 'post',
      url: `${API_LONG_TERM_RENTAL}/long-term-rental/contact`,
      data: {
        ...values,
      },
    })
      .then(() => {
        window.fbq('track', 'Lead', {
          epic: 'long-term-rental-contact',
        })
        return navigate(`/${this.props.lang}/long-term-rental/successfully`)
      })
      .catch(() => this.setState({ isLoading: false }))
  }

  onSubmitQuotation = values => {
    this.setState({ isLoading: true })
    axios({
      method: 'post',
      url: `${API_LONG_TERM_RENTAL}/long-term-rental/quotation`,
      data: {
        ...values,
      },
    })
      .then(() => {
        window.fbq('track', 'Lead', {
          epic: 'long-term-rental-quotation',
        })
        return navigate(`/${this.props.lang}/long-term-rental/successfully`)
      })
      .catch(() => this.setState({ isLoading: false }))
  }

  render() {
    const { t } = this.props
    return (
      <div className="long-term-rental-form">
        <h2 className="text-center mb-5">แบบฟอร์มเช่ารถระยะยาว</h2>
        <Tab active={1}>
          <TabPane title={t('contact.title')}>
            <Contact
              formName={FORM_NAME_CONTACT}
              onSubmit={values => this.onSubmitContact(values)}
              isLoading={this.state.isLoading}
            />
          </TabPane>
          <TabPane title={t('quotation.title')}>
            <Quotation
              formName={FORM_NAME_QUOTATION}
              onSubmit={values => this.onSubmitQuotation(values)}
              isLoading={this.state.isLoading}
            />
          </TabPane>
        </Tab>
      </div>
    )
  }
}

export default withTranslation('long-term-rental-form')(LongTermRentalForm)
